import { Button, Space, Table } from "antd"
import React from "react"
import "./CommonTable.scss"
import { Icon } from "@iconify/react"
import { useDispatch } from "react-redux"

const CommonTable = ({
  dataSource,
  columns,
  scrollHeight,
  rowSelection,
  onRowSelection,
  selectedRowKeys,
  nextPage,
  prevPage,
  pagination,
  prevDisabled,
  nextDisabled,
  scroll,
}) => {
  const dispatch = useDispatch()
  return (
    <>
      <Table
        dataSource={dataSource}
        tableLayout="auto"
        columns={columns}
        rowKey={(record) => record?.id}
        rowSelection={
          rowSelection && {
            type: "checkbox",
            selectedRowKeys: selectedRowKeys,
            onChange: onRowSelection,
          }
        }
        // scroll={{
        //   y: 532,
        //   x: 1000,
        // }}
        scroll={scroll}
        footer={() =>
          pagination && (
            <div className="table-footer">
              <Space>
                <Button
                  size="small"
                  disabled={prevDisabled}
                  onClick={() => dispatch(prevPage())}
                >
                  <Icon icon="fluent:chevron-left-16-filled" />
                </Button>
                <Button
                  size="small"
                  disabled={nextDisabled}
                  onClick={() => dispatch(nextPage())}
                >
                  <Icon icon="fluent:chevron-right-16-filled" />
                </Button>
              </Space>
            </div>
          )
        }
        pagination={false}
      />
    </>
  )
}

export default CommonTable
