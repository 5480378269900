// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart-box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 20px 0px;
  gap: 30px;
}

.productive-bar {
  padding: 20px 0px;
  box-shadow: 1px solid rgba(0, 0, 0, 0.2);
  border: 1px solid #fff;
}
.productive-bar .bar-graph {
  height: 400px;
  width: 100%;
}

@media only screen and (max-width: 992px) {
  .chart-box {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (max-width: 768px) {
  .chart-box {
    grid-template-columns: 1fr;
  }
}`, "",{"version":3,"sources":["webpack://./src/Main/MainPage.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kCAAA;EACA,iBAAA;EACA,SAAA;AACF;;AAEA;EACE,iBAAA;EACA,wCAAA;EACA,sBAAA;AACF;AAAE;EACE,aAAA;EACA,WAAA;AAEJ;;AAEA;EACE;IACE,8BAAA;EACF;AACF;AAEA;EACE;IACE,0BAAA;EAAF;AACF","sourcesContent":[".chart-box {\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr;\n  padding: 20px 0px;\n  gap: 30px;\n}\n\n.productive-bar {\n  padding: 20px 0px;\n  box-shadow: 1px solid rgba(0, 0, 0, 0.2);\n  border: 1px solid #fff;\n  .bar-graph {\n    height: 400px;\n    width: 100%;\n  }\n}\n\n@media only screen and (max-width: 992px) {\n  .chart-box {\n    grid-template-columns: 1fr 1fr;\n  }\n}\n\n@media only screen and (max-width: 768px) {\n  .chart-box {\n    grid-template-columns: 1fr;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
