// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bar-container {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 24px 0px;
}
.bar-container .bar-container-child {
  border: 0.5px solid #d9d9d9;
  display: flex;
  width: 100%;
}

.gap-time-container-in-modal {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 6px;
}

.gap-modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/Main/GapTimeBar.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,iBAAA;AACF;AAAE;EACE,2BAAA;EACA,aAAA;EACA,WAAA;AAEJ;;AAEA;EACE,WAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AACF","sourcesContent":[".bar-container {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  padding: 24px 0px;\n  .bar-container-child{\n    border: 0.5px solid #d9d9d9;\n    display: flex;\n    width: 100%;\n  }\n}\n\n.gap-time-container-in-modal{\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 12px 6px;\n}\n\n.gap-modal-footer{\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
