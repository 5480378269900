// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-design {
  padding: 10px;
  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.2);
}
.card-design .card-heading {
  font-size: 18px;
  font-weight: 600;
  background: #000;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.card-design .card-content {
  font-size: 24px;
  font-weight: 600;
}
.card-design .sm-box {
  height: 90px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Components/CardDesign.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,4CAAA;AACF;AAAE;EACE,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,6BAAA;EACA,oCAAA;AAEJ;AAAE;EACE,eAAA;EACA,gBAAA;AAEJ;AAAE;EACE,YAAA;EACA,WAAA;AAEJ","sourcesContent":[".card-design {\n  padding: 10px;\n  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.2);\n  .card-heading {\n    font-size: 18px;\n    font-weight: 600;\n    background: #000;\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n  }\n  .card-content {\n    font-size: 24px;\n    font-weight: 600;\n  }\n  .sm-box {\n    height: 90px;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
