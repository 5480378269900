// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #000;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
}
.navbar .desktime-logo {
  font-size: 22px;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/Components/Navbar.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,kBAAA;EACA,sBAAA;EACA,mBAAA;EACA,gCAAA;AACF;AAAE;EACE,eAAA;EACA,WAAA;AAEJ","sourcesContent":[".navbar {\n  display: flex;\n  justify-content: space-between;\n  padding: 10px 20px;\n  background-color: #000;\n  align-items: center;\n  border-bottom: 1px solid #e5e5e5;\n  .desktime-logo {\n    font-size: 22px;\n    color: #fff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
