// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.forgot-pass-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 60px;
}
.forgot-pass-container .forgot-pass-form {
  width: 35%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 32px;
}`, "",{"version":3,"sources":["webpack://./src/Login/ForgotPassword.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,iBAAA;AACJ;AAAI;EACI,UAAA;EACA,4CAAA;EACA,aAAA;AAER","sourcesContent":[".forgot-pass-container{\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    padding-top: 60px;\n    .forgot-pass-form{\n        width: 35%;\n        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;\n        padding: 32px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
