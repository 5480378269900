// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top-navbar {
  position: sticky;
  top: 0px;
  z-index: 100000000;
  padding: 10px 20px;
  background-color: #fff;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  border-bottom: 1px solid #e2e2e2;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.05);
}
.top-navbar .user-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.top-navbar .user-profile .user-image {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  overflow: hidden;
}
.top-navbar .user-profile .user-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.top-navbar .user-profile .user-data p {
  margin-bottom: 0px;
  font-size: 14px;
}
.top-navbar .user-profile .user-data h3 {
  margin-bottom: 0px;
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/Main/TopNav.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,QAAA;EACA,kBAAA;EACA,kBAAA;EACA,sBAAA;EACA,aAAA;EACA,yBAAA;EACA,eAAA;EACA,SAAA;EACA,mBAAA;EACA,gCAAA;EACA,4CAAA;AACF;AAAE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,QAAA;AAEJ;AADI;EACE,YAAA;EACA,WAAA;EACA,kBAAA;EACA,gBAAA;AAGN;AAFM;EACE,YAAA;EACA,WAAA;EACA,iBAAA;AAIR;AAAM;EACE,kBAAA;EACA,eAAA;AAER;AAAM;EACE,kBAAA;EACA,eAAA;AAER","sourcesContent":[".top-navbar {\n  position: sticky;\n  top:0px;\n  z-index: 100000000;\n  padding: 10px 20px;\n  background-color: #fff;\n  display: flex;\n  justify-content: flex-end;\n  flex-wrap: wrap;\n  gap: 10px;\n  align-items: center;\n  border-bottom: 1px solid #e2e2e2;\n  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.05);\n  .user-profile {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 5px;\n    .user-image {\n      height: 35px;\n      width: 35px;\n      border-radius: 50%;\n      overflow: hidden;\n      img {\n        height: 100%;\n        width: 100%;\n        object-fit: cover;\n      }\n    }\n    .user-data {\n      p {\n        margin-bottom: 0px;\n        font-size: 14px;\n      }\n      h3 {\n        margin-bottom: 0px;\n        font-size: 12px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
