import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { postQuery } from "../API/PostQuery"
import { getQuery } from "../API/GetQuery"
import { userPutQuery } from "../API/UserPutQuery"

export const DailyActivityFun = createAsyncThunk(
  "send-daily-activity",
  async (data) => {
    const DailyData = await postQuery(
      `${process.env.REACT_APP_BASE_URL}saveDailyActivity`,
      data
    )
    return DailyData?.data
  }
)

export const getAllVisitors = createAsyncThunk("allVisitors", async (data) => {
  const response = await getQuery(
    `${process.env.REACT_APP_BASE_URL}visitor/getAllVisitors?mailId=${data?.email}&page=${data?.page}&size=50`
  )
  return response.data
})
export const addVisitors = createAsyncThunk("addVisitors", async (data) => {
  const response = await postQuery(
    `${process.env.REACT_APP_BASE_URL}visitor/saveVisitor?mailId=${data?.email}`,
    data.data
  )
  return response.data
})

export const editVisitor = createAsyncThunk("editVisitor", async (data) => {
  const response = await userPutQuery(
    `${process.env.REACT_APP_BASE_URL}visitor/editVisitor/${data?.id}?mailId=${data?.email}`,
    data?.data
  )
  return response.data
})

export const DailyActivitySlice = createSlice({
  name: "activity",
  initialState: {
    startActivity: [],
    ActivityLoading: false,
    ActivityError: false,
    loading: "",
    visitorsList: [],
    visitorPage: 0,
  },
  reducers: {
    handleNextPagination: (state, action) => {
      state.visitorPage = state.visitorPage + 1
    },
    handlePrevPagination: (state, action) => {
      state.visitorPage = state.visitorPage >= 0 ? state.page - 1 : 0
    },
  },
  extraReducers: (builder) => {
    builder.addCase(DailyActivityFun.pending, (state, action) => {
      state.ActivityLoading = true
      state.ActivityError = false
    })
    builder.addCase(DailyActivityFun.fulfilled, (state, action) => {
      state.startActivity = action.payload
      state.ActivityLoading = false
      state.ActivityError = false
    })
    builder.addCase(DailyActivityFun.rejected, (state, action) => {
      state.ActivityLoading = false
      state.ActivityError = true
    })

    builder.addCase(getAllVisitors.pending, (state, action) => {
      state.loading = "pending"
    })
    builder.addCase(getAllVisitors.fulfilled, (state, action) => {
      state.visitorsList = action.payload?.content
      state.loading = "success"
    })
    builder.addCase(getAllVisitors.rejected, (state, action) => {
      state.loading = "rejected"
    })
  },
})

export const { handleNextPagination, handlePrevPagination } =
  DailyActivitySlice.actions

export default DailyActivitySlice.reducer
