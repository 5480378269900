export const fakeData = [
  {
    name: "1",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "2",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "3",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "4",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "5",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "6",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "7",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "8",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
]

export const barData = [
  {
    name: "09:00",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "10:00",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "11:00",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "12:00",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "01:00",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "02:00",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "03:00",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "04:00",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "05:00",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
]

export const processData = [
  {
    Id: 13224,
    ProcessName:
      "CodeSetup-stable-e170252f762678dec6ca2cc69aba1570769a5d39.tmp",
    MainWindowTitle: "Setup",
    UserName: "Rahul Jains",
    Description: "rahul.jain@corpseed.com",
    Date: "2024-04-23",
    UsageTimeMinutes: "219.711132953333 minutes",
  },
  {
    Id: 8408,
    ProcessName: "GitHubDesktop",
    MainWindowTitle: "GitHub Desktop",
    UserName: "Rahul Jains",
    Description: "rahul.jain@corpseed.com",
    Date: "2024-04-23",
    UsageTimeMinutes: "40.628797995 minutes",
  },
  {
    Id: 8608,
    ProcessName: "idea64",
    MainWindowTitle: "Desktime – DesktimeApplication.java",
    UserName: "Rahul Jains",
    Description: "rahul.jain@corpseed.com",
    Date: "2024-04-23",
    UsageTimeMinutes: "77.5682804016667 minutes",
  },
  {
    Id: 232,
    ProcessName: "MySQLWorkbench",
    MainWindowTitle: "MySQL Workbench",
    UserName: "Rahul Jains",
    Description: "rahul.jain@corpseed.com",
    Date: "2024-04-23",
    UsageTimeMinutes: "20.0948839166667 minutes",
  },
  {
    Id: 2864,
    ProcessName: "notepad",
    MainWindowTitle: "prcoess_script - Notepad",
    UserName: "Rahul Jains",
    Description: "rahul.jain@corpseed.com",
    Date: "2024-04-23",
    UsageTimeMinutes: "0.380182456666667 minutes",
  },
  {
    Id: 6484,
    ProcessName: "powershell",
    MainWindowTitle: "Administrator: Windows PowerShell",
    UserName: "Rahul Jains",
    Description: "rahul.jain@corpseed.com",
    Date: "2024-04-23",
    UsageTimeMinutes: "0.734860641666667 minutes",
  },
  {
    Id: 8924,
    ProcessName: "TextInputHost",
    MainWindowTitle: "Microsoft Text Input Application",
    UserName: "Rahul Jains",
    Description: "rahul.jain@corpseed.com",
    Date: "2024-04-23",
    UsageTimeMinutes: "220.363688081667 minutes",
  },
]
