// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side-btn {
  width: 100%;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  background-color: #fff;
  border-radius: 10px;
}
.side-btn:hover {
  color: #000;
  text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/SideBarBtn.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,sBAAA;EACA,mBAAA;AACF;AAAE;EACE,WAAA;EACA,qBAAA;AAEJ","sourcesContent":[".side-btn {\n  width: 100%;\n  padding: 10px 20px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  color: #000;\n  background-color: #fff;\n  border-radius: 10px;\n  &:hover {\n    color: #000;\n    text-decoration: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
