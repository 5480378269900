// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-field {
  position: relative;
}
.input-field .side-icon {
  position: absolute;
  top: 12px;
  font-size: 18px;
}
.input-field .my-input {
  width: 100%;
  padding: 8px 24px;
  border: none;
  border-bottom: 1px solid gray;
  margin-bottom: 25px;
}
.input-field .my-input:focus {
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/Login/InputComponent.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAAE;EACE,kBAAA;EACA,SAAA;EACA,eAAA;AAEJ;AAAE;EACE,WAAA;EACA,iBAAA;EACA,YAAA;EACA,6BAAA;EACA,mBAAA;AAEJ;AAAI;EACE,aAAA;AAEN","sourcesContent":[".input-field {\n  position: relative;\n  .side-icon {\n    position: absolute;\n    top: 12px;\n    font-size: 18px;\n  }\n  .my-input {\n    width: 100%;\n    padding: 8px 24px;\n    border: none;\n    border-bottom: 1px solid gray;\n    margin-bottom: 25px;\n\n    &:focus {\n      outline: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
