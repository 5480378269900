// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empty-state {
  width: 750px;
  margin: 40px auto;
  background: #ffffff;
  box-shadow: 1px 2px 10px #e1e3ec;
  border-radius: 4px;
}
.empty-state__content {
  padding: 48px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.empty-state__content .empty-state__icon {
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  border-radius: 200px;
  justify-content: center;
  background-color: #f7fafc;
  box-shadow: 0px 2px 1px #e1e3ec;
}
.empty-state__content .empty-state__icon img {
  width: 170px;
}
.empty-state__content .empty-state__message {
  color: #38a169;
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 0.85rem;
}
.empty-state__content .empty-state__help {
  color: #a2a5b9;
  font-size: 0.875rem;
}

.credit {
  color: #a2a5b9;
  font-size: 0.75rem;
  text-align: center;
}
.credit a {
  color: #444;
}`, "",{"version":3,"sources":["webpack://./src/Components/NoRecordAdded.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,iBAAA;EACA,mBAAA;EACA,gCAAA;EACA,kBAAA;AACF;AACE;EACE,aAAA;EACA,aAAA;EACA,mBAAA;EACA,sBAAA;AACJ;AACI;EACE,YAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,oBAAA;EACA,uBAAA;EACA,yBAAA;EACA,+BAAA;AACN;AACM;EACE,YAAA;AACR;AAGI;EACE,cAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;AADN;AAII;EACE,cAAA;EACA,mBAAA;AAFN;;AAOA;EACE,cAAA;EACA,kBAAA;EACA,kBAAA;AAJF;AAME;EACE,WAAA;AAJJ","sourcesContent":[".empty-state {\n  width: 750px;\n  margin: 40px auto;\n  background: #ffffff;\n  box-shadow: 1px 2px 10px #e1e3ec;\n  border-radius: 4px;\n\n  &__content {\n    padding: 48px;\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n\n    .empty-state__icon {\n      width: 200px;\n      height: 200px;\n      display: flex;\n      align-items: center;\n      border-radius: 200px;\n      justify-content: center;\n      background-color: #f7fafc;\n      box-shadow: 0px 2px 1px #e1e3ec;\n\n      img {\n        width: 170px;\n      }\n    }\n\n    .empty-state__message {\n      color: #38a169;\n      font-size: 1.5rem;\n      font-weight: 500;\n      margin-top: 0.85rem;\n    }\n\n    .empty-state__help {\n      color: #a2a5b9;\n      font-size: 0.875rem;\n    }\n  }\n}\n\n.credit {\n  color: #a2a5b9;\n  font-size: 0.75rem;\n  text-align: center;\n\n  a {\n    color: #444;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
