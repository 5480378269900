// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-page {
  background-color: #093252;
  color: #fff;
  display: flex;
  height: 100vh;
}
.main-page .side-nav {
  width: 250px;
  position: sticky;
  top: 0;
  overflow: auto;
  padding: 0px;
  background-color: #4158d0;
}
.main-page .side-nav::-webkit-scrollbar {
  display: none;
}
.main-page .right-data {
  background-color: #fff;
  flex: 10 1;
  color: #000;
  overflow: auto;
}

@media only screen and (max-width: 768px) {
  .main-page {
    display: flex;
    flex-direction: column;
  }
  .main-page .side-nav {
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/Main/MainOutlet.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,WAAA;EACA,aAAA;EACA,aAAA;AACF;AAAE;EACE,YAAA;EACA,gBAAA;EACA,MAAA;EACA,cAAA;EACA,YAAA;EACA,yBAAA;AAEJ;AADI;EACE,aAAA;AAGN;AAAE;EACE,sBAAA;EACA,UAAA;EACA,WAAA;EACA,cAAA;AAEJ;;AAEA;EACE;IACE,aAAA;IACA,sBAAA;EACF;EAAE;IACE,WAAA;EAEJ;AACF","sourcesContent":[".main-page {\n  background-color: #093252;\n  color: #fff;\n  display: flex;\n  height: 100vh;\n  .side-nav {\n    width: 250px;\n    position: sticky;\n    top: 0;\n    overflow: auto;\n    padding: 0px;\n    background-color: #4158d0;\n    &::-webkit-scrollbar {\n      display: none;\n    }\n  }\n  .right-data {\n    background-color: #fff;\n    flex: 10;\n    color: #000;\n    overflow: auto;\n  }\n}\n\n@media only screen and (max-width: 768px) {\n  .main-page {\n    display: flex;\n    flex-direction: column;\n    .side-nav {\n      width: 100%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
