// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero-section {
  width: 100%;
  height: calc(100vh - 120px);
  background-image: url("https://images.pexels.com/photos/5624978/pexels-photo-5624978.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
  background-size: cover;
  background-position: cover;
  background-repeat: no-repeat;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.hero-section .hero-text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 50vh;
}
.hero-section .hero-text .hero-heading {
  font-size: 35px;
  font-weight: 600;
  color: #fff;
}
.hero-section .hero-text .hero-para {
  font-size: 18px;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/Components/HeroPage.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,2BAAA;EACA,wIAAA;EACA,sBAAA;EACA,0BAAA;EACA,4BAAA;EACA,aAAA;EACA,8BAAA;AACF;AAAE;EACE,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,YAAA;AAEJ;AADI;EACE,eAAA;EACA,gBAAA;EACA,WAAA;AAGN;AADI;EACE,eAAA;EACA,WAAA;AAGN","sourcesContent":[".hero-section {\n  width: 100%;\n  height: calc(100vh - 120px);\n  background-image: url(\"https://images.pexels.com/photos/5624978/pexels-photo-5624978.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1\");\n  background-size: cover;\n  background-position: cover;\n  background-repeat: no-repeat;\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  .hero-text {\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    height: 50vh;\n    .hero-heading {\n      font-size: 35px;\n      font-weight: 600;\n      color: #fff;\n    }\n    .hero-para {\n      font-size: 18px;\n      color: #fff;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
