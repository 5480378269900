// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.process-box {
  border: 1px solid #353535;
  display: grid;
  padding: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  max-height: 500px;
  overflow: auto;
}

@media only screen and (max-width: 992px) {
  .process-box {
    grid-template-columns: 1fr 1fr;
  }
}
@media only screen and (max-width: 768px) {
  .process-box {
    grid-template-columns: 1fr;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/ProcessDataComp.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,aAAA;EACA,aAAA;EACA,kCAAA;EACA,SAAA;EACA,iBAAA;EACA,cAAA;AACF;;AAEA;EACE;IACE,8BAAA;EACF;AACF;AAGA;EACE;IACE,0BAAA;EADF;AACF","sourcesContent":[".process-box {\n  border: 1px solid #353535;\n  display: grid;\n  padding: 20px;\n  grid-template-columns: 1fr 1fr 1fr;\n  gap: 20px;\n  max-height: 500px;\n  overflow: auto;\n}\n\n@media only screen and (max-width: 992px){\n  .process-box {\n    grid-template-columns: 1fr 1fr;\n  }\n}\n\n\n@media only screen and (max-width: 768px){\n  .process-box {\n    grid-template-columns: 1fr;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
