import React, { useCallback, useEffect, useState } from "react"
import CmGap from "../Components/CmGap"
import MdHeading from "../Components/MdHeading"
import CommonTable from "../Components/CommonTable"
import { useDispatch, useSelector } from "react-redux"
import { Button, Form, Input, Modal, notification } from "antd"
import {
  addVisitors,
  editVisitor,
  getAllVisitors,
  handleNextPagination,
  handlePrevPagination,
} from "../Toolkit/DailyActivitySlice"
import { Icon } from "@iconify/react"

const Visitors = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const visitorsList = useSelector((state) => state.activity.visitorsList)
  const page = useSelector((state) => state.activity.visitorPage)
  const currentUser = useSelector((state) => state.auth.currentUser.data)
  const [openModal, setOpenModal] = useState(false)
  const [editData, setEditData] = useState(null)

  useEffect(() => {
    if (currentUser) {
      dispatch(getAllVisitors({ email: currentUser?.email, page: page }))
    }
  }, [dispatch, currentUser, page])

  const handleEditDetails = useCallback(
    (info) => {
      form.setFieldsValue({
        name: info?.name,
        contactNumber: info?.contactNumber,
        visitorMailId: info?.visitorMailId,
        companyName: info?.companyName,
        referenceThrough: info?.referenceThrough,
        purpose: info?.purpose,
      })
      setOpenModal(true)
      setEditData(info)
    },
    [form]
  )

  const handleSubmit = useCallback(
    (values) => {
      let obj = {
        data: values,
        email: currentUser?.email,
      }
      if (editData) {
        obj.id = editData?.id
        dispatch(editVisitor(obj))
          .then((resp) => {
            if (resp.meta.requestStatus === "fulfilled") {
              notification.success({ message: "Visitor edited successfully" })
              dispatch(getAllVisitors({ email: currentUser?.email, page: page }))
              form.resetFields()
              setOpenModal(false)
            } else {
              notification.error({ message: "Something went wrong !." })
            }
          })
          .catch(() => {
            notification.error({ message: "Something went wrong !." })
          })
      } else {
        dispatch(addVisitors(obj))
          .then((resp) => {
            if (resp.meta.requestStatus === "fulfilled") {
              notification.success({ message: "Visitor added successfully" })
              dispatch(getAllVisitors({ email: currentUser?.email, page: page }))
              form.resetFields()
              setOpenModal(false)
            } else {
              notification.error({ message: "Something went wrong !." })
            }
          })
          .catch(() => {
            notification.error({ message: "Something went wrong !." })
          })
      }
    },
    [editData, dispatch, currentUser,page]
  )

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Contact no.",
      dataIndex: "contactNumber",
    },
    {
      title: "Visitor's mailId",
      dataIndex: "visitorMailId",
    },
    {
      title: "Company name",
      dataIndex: "companyName",
    },
    {
      title: "Reference",
      dataIndex: "referenceThrough",
    },
    {
      title: "Edit",
      dataIndex: "edit",
      render: (_, record) => (
        <Button
          type="text"
          size="small"
          onClick={() => handleEditDetails(record)}
        >
          <Icon icon="fluent:edit-24-regular" />
        </Button>
      ),
    },
  ]

  return (
    <CmGap>
      <div className="align-between">
        <MdHeading data={`Visitors list`} />
        <div>
          <Button type="primary" onClick={() => setOpenModal(true)}>
            Add visitor
          </Button>
        </div>
      </div>

      <CommonTable
        dataSource={visitorsList}
        columns={columns}
        pagination={true}
        nextPage={handleNextPagination}
        prevPage={handlePrevPagination}
        scroll={{y:532,x:1700}}
        prevDisabled={page === 0 ? true : false}
        nextDisabled={visitorsList?.length < 50 ? true : false}
      />
      <Modal
        title={editData ? `Edit visitor's details` :  `Add visitor's details`}
        open={openModal}
        onCancel={() => setOpenModal(false)}
        onClose={() => setOpenModal(false)}
        onOk={() => form.submit()}
        okText="Submit"
      >
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "please enter the name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Contact number"
            name="contactNumber"
            rules={[{ required: true, message: "please enter contact number" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="E mail"
            name="visitorMailId"
            rules={[
              { required: true, type: "email", message: "please enter email" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Company name"
            name="companyName"
            rules={[{ required: true, message: "please enter company name" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Purpose/Reason"
            name="purpose"
            rules={[
              { required: true, message: "please enter visitor's purpose" },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            label="Reference"
            name="referenceThrough"
            rules={[{ required: true, message: "please enter reference" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </CmGap>
  )
}

export default Visitors
